import { Icon } from '@iconify/react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from 'react-router-dom';

const boxStyle = {
    border: '1px solid #cecece',
    borderRadius: '24px',
    padding: 3,
    minHeight: '150px',
    boxSizing: 'border-box',
    height: '100%',
    backdropFilter: 'blur(3px)',
    transition: 'box-shadow 0.5s, transform 0.5s',
};

export default function InfoCard({ title, description, path, icon, photo, aspectRatio, tag, tagColor='var(--btn-green)', shadowColor="#daffd1", isSolid=true, isLocked=false }) {
    const navigate = useNavigate();
    return (
        <Box
            sx={{ ...boxStyle, 
                backgroundColor: isSolid ? 'white' : '#ffffffc3',
                '&:hover' : {
                    cursor: 'pointer',
                    transform: 'translate(-8px,-8px)',
                    boxShadow: `8px 8px ${shadowColor}`,
                }
            }}
            onClick={() => {path ? navigate(path) : navigate("#")}}
        >
            { icon 
                ? 
                <Icon icon={icon} style={{ fontSize: '3em', color: '#00000042' }} />
                : ((photo && aspectRatio) ?
                <Box sx={{ borderRadius: '8px', mb: 1, aspectRatio: aspectRatio, background: `var(--bg-light) url(${photo}) no-repeat center`, backgroundSize: 'cover' }} />
                :
                <></>)
            }
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                {
                    title &&
                    <Typography className='title-tab'>
                        { title }
                    </Typography>
                }
                {
                    tag &&
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            px: 2,
                            py: 0.5,
                            mx: 2,
                            borderRadius: '2em',
                            backgroundColor: tagColor
                        }}
                    >
                        <Typography variant='subtitle2' sx={{ fontSize: '0.6em', color: "white" }}>
                            {tag}
                        </Typography> 
                    </Box>
                }
                {
                    isLocked &&
                    <Icon icon={'material-symbols:lock'} style={{ fontSize: '1.2em', color: '#00000042' }} />
                }
            </Box>

            {
                description &&
                <Typography variant='subtitle1' color='text.secondary'>
                    { description }
                </Typography>
            }
        </Box>
    )
}