import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import PageFooter from '../../common/PageFooter';
import CustomGridContent from '../../common/components/CustomGridContent';
import '../../print.css';
import PageHeader from '../components/nav/PageHeader';
import FetchCalcModal from '../components/popups/FetchCalcModal';
import ResultLayout from '../layouts/ResultLayout';

export default function ResultPage() {
    const { state } = useLocation();
    const navigate = useNavigate();
    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
            <PageHeader />
            <Box className="light-background remove-background-print" sx={{ height: '100%', flexGrow: '1' }}>
                <CustomGridContent className='remove-padding-print'>
                {
                    ( state && state?.calcResults !== null ) ?
                        <ResultLayout calcResults={state.calcResults} />
                    :
                        <Box sx={{ p: 3, paddingTop: '4em' }}>
                            <Typography variant='h4'>Unable to render results</Typography>
                            <Typography gutterBottom>No data found. Please try again.</Typography>
                            <Button className='pill_button submit_button' onClick={() => navigate(process.env.REACT_APP_CC_ROOT +'/calculator')}>Recalculate</Button>
                            <FetchCalcModal />
                        </Box>
                }
                </CustomGridContent>
            </Box>
            <PageFooter />
        </Box>
    );
}
