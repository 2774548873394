import styled from '@emotion/styled';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';
import PageFooter from '../../common/PageFooter';
import NavSidebar from '../../common/components/nav/NavSidebar';
import FetchCalcModal from '../components/popups/FetchCalcModal';
import logoImg from '../images/NUS_SGFIN_logo.png';
import pic from '../images/mbs1.jpg';
import pic2 from '../images/mbs1_labelled.jpg';

const Subheader = styled(Typography) ({
    fontSize: '1.2em',
    fontWeight: '700',
})

function H5Subheader({ children, ...props }) {
    return (
        <Subheader variant='h5' {...props}>{children}</Subheader>
    )
}

export default function LandingPage() {
    const navigate = useNavigate();
    return (
        <>
        <AppBar position="sticky" sx={{ backgroundColor: 'white', boxShadow: 'none' }} >
			<Container maxWidth="xl">
				<Toolbar disableGutters>
                    <NavSidebar />
                    {/* <Box sx={{ my: 4, mx: 1 }} > */}
                    <Box sx={{ width: { xs: '100%', sm: '50%', md: '30%' }, m: 4 }} >
                        <a href="/">
                            {/* <Typography variant='h5' sx={{ color: "#003d7c", fontSize: { xs: '1em', md: '2em' }, fontWeight: '100 !important' }}>
                                Sustainable and Green Finance Institute
                            </Typography> */}
                            <img src={logoImg} style={{  width: '100%', marginBottom: 4 }} alt="NUS SGFIN logo" />
                        </a>     
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
        <Box sx={{ minHeight: '60vh' }}>
			<Container maxWidth="xl">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingY: '2em' }}>
                    <Grid container spacing={4}>
                        <Grid md={0} lg={4}>
                            <Box sx={{ borderRadius: '8px', aspectRatio: 1, background: `var(--bg-light) url(${pic}) no-repeat center`, backgroundSize: 'cover' }}>
                            <Box sx={{ borderRadius: '8px', aspectRatio: 1, background: `var(--bg-light) url(${pic2}) no-repeat center`, backgroundSize: 'cover', transition: 'opacity 0.4s', opacity: 0, '&:hover' : { opacity: 100 } }}>
                            </Box>
                            </Box>
                        </Grid>
                        <Grid md={12} lg={8}>
                            <Typography variant='h3'>
                                MICE Carbon Calculator
                            </Typography>
                            <Typography variant='body1' color='text.secondary' marginY="2em">
                                A toolkit built to help event organisers, planners and regulators calculate and track carbon footprint for meetings, incentives, conventions and exhibitions (MICE) in Singapore. To achieve this, this toolkit identifies major sources of carbon footprint over the event period and estimate carbon emissions based on emission factors from credible data sources. The calculator is aligned with leading event carbon frameworks including the Net Zero Carbon Events (NZCE) with contextualised data and design considerations more suited for events in Singapore. 
                            </Typography>
                            <Box>
                                <Button className='pill_button submit_button' onClick={() => navigate(process.env.REACT_APP_CC_ROOT +'/calculator')}>Use the calculator</Button>
                            </Box>
                            <FetchCalcModal />
                        </Grid>
                    </Grid>
                </Box>
            </Container>


            <Box className="light-background" sx={{ display: 'flex', padding: '6em 2em' }}>
			    <Container maxWidth="xl">
                    <Grid container spacing={2}>
                        <Grid xs={12} md={6}>
                            <Subheader variant='h5'>Introduction</Subheader>
                            <Typography>
                            The goal of this toolkit is to help event organisers, planners and regulators calculate and track carbon footprint for meetings, incentives, conventions and exhibitions (MICE) in Singapore. To achieve this goal, this toolkit identifies major sources of carbon footprint throughout a business event and estimate greenhouse gas (GHG) emissions based on statistics from the Singapore Tourism Board (STB) and other regulatory agencies home and abroad. 
                            </Typography>

                            <Subheader variant='h5' sx={{ mt: '3em' }}>
                                What are the three major components?
                            </Subheader>
                            <ul>
                                <li>Transportation</li>
                                <li>Hotel Accommodation</li>
                                <li>On-site Activities</li>
                            </ul>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <iframe style={{ width: '100%', aspectRatio: '16/9' }} src="https://www.youtube.com/embed/j9tFp87diRQ" title="SGFIN Mice Carbon Calculator" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </Grid>
                    </Grid>
                    
                    <Subheader variant='h5'>How can you use this toolkit?</Subheader>
                    As you fill in the data in the form: 
                    <ul>
                        <li>
                            Please prioritise global inputs including number of attendees (physical and virtual; local and international) and event period to ensure the accuracy of the results. 
                        </li>
                        <li>
                            If the data is not filled, the calculator will estimate footprint based on baseline values. For inputs in "Accommodation" and "Catering" sections, users may choose different baselines to estimate the emission. 
                        </li>
                    </ul>

                    <H5Subheader sx={{ mt: '3em' }}>
                        What are the methodologies used?
                    </H5Subheader>
                    <ul>
                        <li>
                        Net Zero Carbon Events (NZCE) framework for event carbon footprint
                        </li>
                        <li>
                        Hotel Carbon Measurement Initiative (HCMI) framework for emissions from accommodation
                        </li>
                        <li>
                        International Civil Aviation Organisation (ICAO) carbon calculator for commercial air travel
                        </li>
                    </ul>

                    <H5Subheader sx={{ mt: '3em' }}>
                        Who is it designed for?
                    </H5Subheader>
                    <ul>
                        <li>
                        Singapore MICE event organisers
                        </li>
                        <li>
                        Regulators, analysts and researchers
                        </li>
                    </ul>

                    <H5Subheader sx={{ mt: '3em' }}>
                        What will it tell you?
                    </H5Subheader>
                    <ul>
                        <li>
                        Total carbon footprint of this event in tonnes of CO&#x2082;e (Carbon Dioxide equivalent)
                        </li>
                        <li>
                        Carbon emission intensity (per capita carbon footprint per day) of the event with benchmarks
                        </li>
                    </ul>
                </Container>
            </Box>
        </Box>
        <PageFooter />
    </>
    )
}