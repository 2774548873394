import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useContext, useState } from 'react';
import { FormStructure as fs } from "../../consts/formStructure";
import {
    updateOverallData
} from '../../helpers/FormHelper';
import { OverallDataContext } from '../../pages/CalculatorPage';
import CalcAccordion from '../CalcAccordion';
import FormSubsection from '../FormSubsection';
import SwitchableSubsection from '../SwitchableSubsection';
import FormInputField from '../inputs/FormInputField';

export default function TabContent({ tabName }) {
    const [overallData, setData] = useContext(OverallDataContext).allData;

    const [tabData, setTabData] = useState({});             // Changed at every updated field

    const updateData = fieldData => {
        updateOverallData(fieldData, setData, overallData, setTabData, tabData, tabName);
    }
    
    return (
        <>
        {fs[tabName] && 
            <>
                <Box sx={{ my: 2 }}>
                    <Typography className="title-tab">{ fs[tabName].tabTitle }</Typography>
                    <Typography variant='body2'>{ fs[tabName].tabDescription }</Typography>
                </Box>
                {
                    fs[tabName].sections.map((sect, sect_index) => {
                        return (
                        <CalcAccordion className="form-section" title={sect.title} sectionTag={sect.tag} isOpen={sect.isOpen} key={sect_index} >
                            {
                                sect.subsections.map((sub, sub_index) => {
                                    return (
                                    <Box key={sub_index}>
                                    {
                                        sub.isSwitchable ?
                                            <SwitchableSubsection
                                                tabName={tabName}
                                                section_tag={sect.tag}
                                                subsection={sub}
                                                updateData={updateData}
                                            />
                                        :
                                            <FormSubsection title={sub.title}>
                                                {
                                                    sub.fields.map((field, f_index) => {
                                                        return (
                                                            <FormInputField
                                                                field={field}
                                                                tabName={tabName}
                                                                key={f_index}
                                                                onChangeHandler={updateData}
                                                            />
                                                        );
                                                    })
                                                }
                                            </FormSubsection>
                                    }
                                    </Box>
                                    );
                                })
                            }
                        </CalcAccordion>
                        );
                    })
                }
            </>
        }
        </>
    );
  }