import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalBase from '../../../common/components/cards/ModalBase';
import EllipsisLoader from '../loaders/EllipsisLoader';
import FormInputPrompt from './FormInputPrompt';

export default function FetchCalcModal() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const navigate = useNavigate();

    const [referenceId, setReferenceId] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleFetch = () => {
        setIsLoading(true);
        if (referenceId) {
            const url = process.env.REACT_APP_CC_LAMBDA + '?' + new URLSearchParams({ referenceId: referenceId });
            fetch(url, 
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(
                function (response) {
                    return response.json();
                }
            ).then(
                function(data) {
                    let results = JSON.parse(data.body);
                    if (data.status === "success") {
                        navigate(
                            process.env.REACT_APP_CC_ROOT + '/results',
                            { state : {
                                calcResults : results
                            }}
                        )
                    } else if (data.status === "error") {
                        setErrorMsg(results.message)
                    }
                }
            ).catch(
                function(error) {
                    if (process.env.NODE_ENV === 'development') {
                        if (typeof error.json === "function") {
                            error.json().then(jsonError => {
                                console.log("Json error from API");
                                console.log(jsonError);
                            }).catch(genericError => {
                                console.log("Generic error from API");
                                console.log(error.statusText);
                            });
                        } else {
                            console.log("Fetch error");
                            console.log(error);
                        }
                    }
                    setErrorMsg("Something went wrong. Please try again later!")
                }
            )
            .finally(() => {
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
            setErrorMsg('Please enter a value!')
        }
    }
    
    return (
        <ModalBase 
            title="Fetch Results of a Previous Calculation"
            open={open}
            setOpen={setOpen}
            opener={
                <Box sx={{ mt: 2 }}>
                    <Button className='pill_button secondary_button' onClick={handleOpen}>Fetch Results</Button>
                </Box>
            }
            >
                <Grid container spacing={2}>
                    <Grid sm={12} lg={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography htmlFor='referenceID_input'>Reference ID</Typography>
                        <FormInputPrompt 
                            id_name="referenceID_input"
                            content="If you had used this calculator before, a reference ID would have been generated for your results."
                        />
                        </Grid>
                    <Grid sm={12} lg={4}>
                        <TextField 
                            name='referenceID_input' id='referenceID_input' 
                            type='text'
                            fullWidth
                            value={referenceId}
                            onChange={(e) => setReferenceId(e.target.value.trim())}
                            error={(errorMsg !== '')}
                            helperText={errorMsg ? errorMsg : ''}
                        />
                    </Grid>
                    <Grid sm={12} lg={4}>
                        {
                            isLoading ?
                            <Button className='pill_button secondary_button'>
                                &nbsp;<EllipsisLoader />&nbsp;
                            </Button>
                            :
                            <Button type='submit' className='pill_button submit_button' onClick={handleFetch}>
                            Get Calculation results
                            </Button>
                        }
                    </Grid>
                </Grid>
        </ModalBase>
    )
}